import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../store/actions/index';
import Button from '../UI/Buttons/Index';

const Filter = (props) => {
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [barangay, setBarangay] = useState('');
  const [barangayOptions, setBarangayOptions] = useState([]);
  const [civilStatus, setCivilStatus] = useState('');
  const [memberFrom, setmemberFrom] = useState('');
  const [memberStatus, setMemberStatus] = useState('');
  const [memberTo, setmemberTo] = useState('');
  const [memberType, setMemberType] = useState('');
  const [chkAge, setChkAge] = useState(false);
  const [chkBarangay, setChkBarangay] = useState(false);
  const [chkCivilStatus, setChkCivilStatus] = useState(false);
  const [chkDeseased, setChkDeseased] = useState(false);
  const [chkMemberStatus, setChkMemberStatus] = useState(false);
  const [chkMemberType, setchkMemberType] = useState(false);
  const [chkMembershipDate, setChkMembershipDate] = useState(false);

  const {
    barangays,
    hidden,
    loading,
    onFetchBarangays,
    onFilterMembers,
    onResetMembers,
    name,
  } = props;

  useEffect(() => {
    onFetchBarangays();
  }, [onFetchBarangays]);

  useEffect(() => {
    if (barangays) {
      const brs = barangays.map((b) => {
        if (chkBarangay) {
          setBarangay(barangays[0].id);
        }
        return (
          <option key={b.id} value={b.id}>
            {b.name}
          </option>
        );
      });
      setBarangayOptions(brs);
    }
  }, [barangays, chkBarangay]);

  useEffect(() => {
    if (chkMemberStatus && memberStatus === '') {
      setMemberStatus(1);
    }
  }, [chkMemberStatus, memberStatus]);

  useEffect(() => {
    if (chkMemberType && memberType === '') {
      setMemberType(0);
    }
  }, [chkMemberType, memberType]);

  const barangayChangeHandler = (e) => {
    setBarangay(e.target.value);
  };

  const civilStatusChangeHandler = (e) => {
    setCivilStatus(e.target.value);
  };

  const filterMembersHandler = (e) => {
    e.preventDefault();
    onResetMembers();
    const data = {
      barangay: barangay,
      civilStatus: civilStatus,
      deseased: chkDeseased,
      from: from,
      memberFrom: memberFrom,
      memberStatus: memberStatus,
      memberTo: memberTo,
      memberType: memberType,
      name: name,
      to: to,
    };
    onFilterMembers(data);
  };

  const chkBarangayHandler = useCallback(() => {
    setChkBarangay(!chkBarangay);
    if (chkBarangay) {
      setBarangay('');
    }
  }, [chkBarangay]);

  const chkMemberTypeHandler = useCallback(() => {
    setchkMemberType(!chkMemberType);
    if (chkMemberType) {
      setMemberType('');
    }
  }, [chkMemberType]);

  const chkCivilStatusHandler = useCallback(() => {
    setChkCivilStatus(!chkCivilStatus);
    if (chkCivilStatus) {
      setCivilStatus('');
    } else {
      setCivilStatus('SINGLE');
    }
  }, [chkCivilStatus]);

  const chkAgeHandler = useCallback(() => {
    setChkAge(!chkAge);
    if (chkAge) {
      setFrom('');
      setTo('');
    }
  }, [chkAge]);

  const chkMemberDateHandler = useCallback(() => {
    setChkMembershipDate(!chkMembershipDate);
    if (chkMembershipDate) {
      setmemberFrom('');
      setmemberTo('');
    }
  }, [chkMembershipDate]);

  const chkMemberStatusHandler = useCallback(() => {
    setChkMemberStatus(!chkMemberStatus);
    if (chkMemberStatus) {
      setMemberStatus('');
    }
  }, [chkMemberStatus]);

  const chkDeseasedHandler = useCallback(() => {
    setChkDeseased(!chkDeseased);
  }, [chkDeseased]);

  return (
    <form onSubmit={filterMembersHandler} className={hidden ? 'hidden' : ''}>
      <div className="flex flex-wrap -mx-auto mb-6 px-6 py-4 shadow-md">
        <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="barangay"
          >
            Barangay
          </label>
          <div className="flex items-center">
            <input
              id="barangay-checkbox"
              type="checkbox"
              value={true}
              className="w-4 mr-2 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              onChange={chkBarangayHandler}
              checked={chkBarangay}
            />
            <select
              className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="barangay"
              name="barangay"
              type="text"
              onChange={barangayChangeHandler}
              disabled={!chkBarangay}
            >
              {barangayOptions}
            </select>
          </div>
          <div className="flex flex-wrap mx-auto mb-2 py-4">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="age"
            >
              Age
            </label>
            <div className="flex items-center">
              <input
                id="age-checkbox"
                type="checkbox"
                value={true}
                className="w-4 mr-2 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                onChange={chkAgeHandler}
              />
              <div className="md:w-1/2 w-full px-1 mb-2">
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="ageFrom"
                  name="ageFrom"
                  type="number"
                  onChange={(e) => setFrom(e.target.value)}
                  value={from}
                  disabled={!chkAge}
                />
              </div>
              <div className="md:w-1/2 w-full px-1 mb-2">
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="ageTo"
                  name="ageTo"
                  type="number"
                  onChange={(e) => setTo(e.target.value)}
                  value={to}
                  disabled={!chkAge}
                />
              </div>
            </div>
          </div>
          <div className="md:w1/2 w-full mb-2">
            <div className="flex items-center">
              <input
                id="deseased-checkbox"
                type="checkbox"
                value={true}
                className="w-4 mr-2 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                onChange={chkDeseasedHandler}
                checked={chkDeseased}
              />
              <label htmlFor="deseased-checkbox">Deseased</label>
            </div>
          </div>
        </div>
        <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="memberType"
          >
            Member Type
          </label>
          <div className="flex items-center">
            <input
              id="memberType-checkbox"
              type="checkbox"
              value={true}
              className="w-4 mr-2 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              onChange={chkMemberTypeHandler}
              checked={chkMemberType}
            />
            <select
              className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="memberType"
              name="memberType"
              type="text"
              onChange={(e) => setMemberType(e.target.value)}
              disabled={!chkMemberType}
            >
              <option value={0} selected>
                Regular
              </option>
              <option value={1}>Premium</option>
            </select>
          </div>
          <div className="flex flex-wrap mx-auto mb-6 py-4">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="membershipDate"
            >
              Member Since From
            </label>
            <div className="flex items-center">
              <input
                id="membershipDate-checkbox"
                type="checkbox"
                value={true}
                className="w-4 mr-2 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                onChange={chkMemberDateHandler}
              />
              <div className="md:w-1/2 w-full px-1 mb-6 md:mb-4">
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="memberFrom"
                  name="memberFrom"
                  type={chkMembershipDate ? 'date' : 'text'}
                  onChange={(e) => setmemberFrom(e.target.value)}
                  value={memberFrom}
                  disabled={!chkMembershipDate}
                />
              </div>
              <div className="md:w-1/2 w-full px-1 mb-6 md:mb-4">
                <input
                  className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="memberTo"
                  name="memberTo"
                  type={chkMembershipDate ? 'date' : 'text'}
                  onChange={(e) => setmemberTo(e.target.value)}
                  value={memberTo}
                  disabled={!chkMembershipDate}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="civilStatus"
          >
            Civil Status
          </label>
          <div className="flex items-center">
            <input
              id="civilStatus-checkbox"
              type="checkbox"
              value={true}
              className="w-4 mr-2 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              onChange={chkCivilStatusHandler}
              checked={chkCivilStatus}
            />
            <select
              className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="civilStatus"
              name="civilStatus"
              type="text"
              onChange={civilStatusChangeHandler}
              disabled={!chkCivilStatus}
            >
              <option key="1" value="SINGLE">
                SINGLE
              </option>
              <option key="2" value="MARRIED">
                MARRIED
              </option>
              <option key="3" value="WIDOWED">
                WIDOWED
              </option>
              <option key="4" value="LEGALLY SEPERATED">
                LEGALLY SEPERATED
              </option>
              <option key="5" value="LIVE IN">
                LIVE IN
              </option>
            </select>
          </div>
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-4"
            htmlFor="membershipStatus"
          >
            Membership Status
          </label>
          <div className="flex items-center">
            <input
              id="membershipStatus-checkbox"
              type="checkbox"
              value={true}
              className="w-4 mr-2 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              onChange={chkMemberStatusHandler}
              checked={chkMemberStatus}
            />
            <select
              className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="membershipStatus"
              name="membershipStatus"
              type="text"
              onChange={(e) => setMemberStatus(e.target.value)}
              disabled={!chkMemberStatus}
            >
              <option value={1} selected>
                Active
              </option>
              <option value={0}>In Active</option>
            </select>
          </div>
        </div>
        <div className="w-full px-1 mb-2 md:mb-0">
          <div className="flex justify-end">
            <Button
              type="submit"
              disabled={
                !chkAge &&
                !chkBarangay &&
                !chkCivilStatus &&
                !chkMembershipDate &&
                !chkMemberType &&
                !chkMemberStatus &&
                !chkDeseased
              }
            >
              {loading ? 'Loading Please Wait...' : 'Apply Filter'}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    barangays: state.barangays.barangays,
    occupations: state.occupations.occupations,
    loading: state.members.loading,
    error: state.members.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchBarangays: () => dispatch(actions.fetchAllBarangays()),
    onFilterMembers: (data) => dispatch(actions.filterMembers(data)),
    onFetchOccupations: () => dispatch(actions.fetchAllOccupations()),
    onResetMembers: () => dispatch(actions.resetMembers()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
