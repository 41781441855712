import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import classNames from 'classnames';
import * as moment from 'moment';

import * as actions from '../../store/actions/index';
import { getFileType } from '../../helpers';

import Button from '../UI/Buttons/Index';
import Modal from '../UI/Modal/Index';
import Navigation from '../UI/Navigation/Index';
import PhotoDropzone from '../Common/PhotoDropzone';
import SignatureDropzone from '../Common/SignatureDropzone';
import JSZip from 'jszip';
import Table from '../UI/Tables/Index';

const View = (props) => {
  const [age, setAge] = useState(0);
  const [images, setImages] = useState('');
  const [signatureData, setSignatureData] = useState('');
  const [id, setId] = useState('');
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState(30);
  const [errors, setErrors] = useState({});

  const location = useLocation().search;
  const navigate = useNavigate();

  const {
    attachments,
    loadingAttachment,
    member,
    onCreatePayment,
    onFetchMemberPicture,
    onFetchMemberSignature,
    onFetchOneMember,
    onResetAttachments,
    onResetPassword,
    onResetPayments,
    payment,
    photoCreated,
    picture,
    signature,
    signatureCreated,
  } = props;

  useEffect(() => {
    if (location) {
      const param = new URLSearchParams(location).get('id');
      setId(param);
    }
  }, [location]);

  useEffect(() => {
    if (attachments) {
      const zip = new JSZip();
      const photo = zip.folder('photo');
      const signature = zip.folder('signature');
      for (let index = 0; index < attachments.length; index++) {
        const el = attachments[index];
        const mimeType = getFileType(el.mimeType);
        if (el.type === 1) {
          photo.file(`${data.mid}.${mimeType}`, el.file, {
            base64: true,
          });
        }
        if (el.type === 2) {
          signature.file(`${data.mid}.${mimeType}`, el.file, {
            base64: true,
          });
        }
      }
      zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, `${data.lastname} ${data.firstname}.zip`);
      });
    }
    return () => {
      onResetAttachments();
    };
  }, [
    attachments,
    onResetAttachments,
    data.firstname,
    data.lastname,
    data.mid,
  ]);

  useEffect(() => {
    if (payment) {
      toast.success('Payment was sent for approval!', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      setOpen(false);
      data.status = payment.status;
      return () => {
        onResetPayments();
      };
    }
  }, [payment, onResetPayments, data]);

  useEffect(() => {
    if (moment(data.birthdate).isValid()) {
      setAge(moment().diff(data.birthdate, 'years'));
    }
  }, [data]);

  useEffect(() => {
    if (photoCreated) {
      onFetchMemberPicture({
        id: id,
        type: 1,
      });
    }
  }, [onFetchMemberPicture, photoCreated, id]);

  useEffect(() => {
    if (id) {
      onFetchMemberPicture({
        id: id,
        type: 1,
      });
    }
  }, [onFetchMemberPicture, id]);

  useEffect(() => {
    if (id) {
      onFetchMemberSignature({
        id: id,
        type: 2,
      });
    }
  }, [onFetchMemberSignature, id]);

  useEffect(() => {
    if (signatureCreated) {
      onFetchMemberSignature({
        id: id,
        type: 2,
      });
    }
  }, [signatureCreated, id, onFetchMemberSignature]);

  useEffect(() => {
    if (id) {
      onFetchOneMember({ id: id });
    }
  }, [onFetchOneMember, id]);

  useEffect(() => {
    if (member) {
      onResetAttachments();
      setData(member);
    }
  }, [member, onResetAttachments]);

  useEffect(() => {
    if (picture) {
      setImages(picture);
    }
  }, [picture]);

  useEffect(() => {
    if (signature) {
      setSignatureData(signature);
    }
  }, [signature]);

  const amountChangeHandler = (e) => {
    let err = {};
    err = Object.assign({}, errors);
    delete err.amount;
    setAmount(e.target.value);
    setErrors(err);
  };

  const renewButtonHandler = () => {
    let err = {};
    if (amount <= 0) {
      err.amount = 'Please enter amount';
      const input = document.getElementById('amount');
      input.focus();
    }
    setErrors(err);
    const isValid = Object.keys(err).length === 0;
    if (isValid) {
      const data = {
        id: id,
        amount: amount,
        paymentOption: 'bce559fe-4d21-11ed-8a2b-00155d5mm5b7',
        type: 'S',
      };
      Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Renew Membership',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          onCreatePayment(data);
        }
      });
    }
  };

  const benefitColumns = useMemo(
    () => [
      {
        Header: 'Benefits Availed',
        hideHeader: false,
        columns: [
          {
            Header: 'Name',
            accessor: 'sys_benefit.name',
          },
          {
            Header: 'Date Applied',
            accessor: 'createdAt',
            Cell: ({ value }) => moment(value).format('MMMM DD, YYYY'),
          },
          {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ value }) => (
              <span
                className={classNames(
                  'text-xs font-semibold mr-2 px-2.5 py-0.5 rounded',
                  value === 'APPROVED'
                    ? 'bg-green-100 text-green-800  dark:bg-green-200 dark:text-green-800'
                    : value === 'FOR EVALUATION'
                    ? 'bg-blue-100 text-blue-800  dark:bg-blue-200 dark:text-blue-800'
                    : 'bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-800'
                )}
              >
                {value}
              </span>
            ),
          },
        ],
      },
    ],
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Payment History',
        hideHeader: false,
        columns: [
          {
            Header: 'Transaction Number',
            accessor: 'TN',
          },
          {
            Header: 'Amount',
            accessor: 'amount',
          },
          {
            Header: 'Date',
            accessor: 'createdAt',
          },
          {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ value }) => (
              <span
                className={classNames(
                  'text-xs font-semibold mr-2 px-2.5 py-0.5 rounded',
                  value === 1
                    ? 'bg-green-100 text-green-800  dark:bg-green-200 dark:text-green-800'
                    : value === 2
                    ? 'bg-blue-100 text-blue-800  dark:bg-blue-200 dark:text-blue-800'
                    : 'bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-800'
                )}
              >
                {value === 1
                  ? 'Approved'
                  : value === 2
                  ? 'For Evaluation'
                  : 'Disapproved'}
              </span>
            ),
          },
        ],
      },
    ],
    []
  );

  const paymentOptionModal = (
    <Modal
      title="Renew Membership"
      btnLabel="Renew"
      enter={renewButtonHandler}
      cancel={() => setOpen(false)}
    >
      <div className="w-full px-3 mb-6 md:mb-4">
        <label
          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
          htmlFor="amount"
        >
          Amount
        </label>
        <select
          className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="amount"
          name="amount"
          onChange={amountChangeHandler}
          autoFocus
        >
          <option key={30} value={30} selected={30}>
            Regular (30)
          </option>
          <option key={150} value={150}>
            Premium (150)
          </option>
        </select>
        <p className="text-red-800">{errors.amount}</p>
      </div>
    </Modal>
  );

  const resetPasswordHandler = () => {
    Swal.fire({
      title: 'Reset password?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Reset member password',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        onResetPassword({ id: id });
        Swal.fire(
          'Password Reset!',
          data.birthdate?.replace(/-/g, ''),
          'success'
        );
      }
    });
  };
  const paymentItems =
    data?.payments?.length > 0 &&
    data?.payments?.sort((a, b) => {
      return new Date(a.createdAt) - new Date(b.createdAt);
    });

  const isForEvaluation =
    paymentItems.length > 0 && paymentItems[0].status === 2;
  return (
    <Fragment>
      <Navigation />
      <ToastContainer />
      {open && paymentOptionModal}
      <div className="container mt-6 w-full">
        <div className="relative overflow-x-auto shadow-md bg-white">
          <div className="flex flex-wrap -mx-auto mb-0 px-4">
            <div className="md:w-1/2 w-full px-3 mx:w-full">
              <h5 className="md:w-1/2 mt-5 mb-6 md:mb-0 text-2xl font-bold">
                Personal Information
              </h5>
            </div>
            {data && data.deseased === 0 && (
              <div className="md:w-1/2 w-full px-3 mx:w-full">
                <Button
                  className="h-8 px-8 py-1 mt-6 text-sm font-medium mb-2 float-right"
                  onClick={resetPasswordHandler}
                >
                  <div className="flex items-center px-1">
                    <b>Reset Password</b>
                  </div>
                </Button>
                <Button
                  className="h-8 px-8 py-1 mt-6 text-sm font-medium mx-4 mb-2 float-right"
                  onClick={() => {
                    const path = {
                      pathname: '/members/edit',
                      search: createSearchParams({ id: data.id }).toString(),
                    };
                    navigate(path);
                  }}
                >
                  <div className="flex items-center px-1">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      className="h-4 w-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                      ></path>
                    </svg>
                    <b>Edit</b>
                  </div>
                </Button>
                {Object.keys(data).length > 1 && data.status === 1 ? (
                  <span className="bg-green-700 hover:bg-green-800 text-white font-bold py-1 px-2 mt-6 float-right xs:w-full">
                    Active
                  </span>
                ) : Object.keys(data).length > 1 && data.status === 2 ? (
                  <span className="bg-blue-900 text-white font-bold py-1 px-2 mt-6 float-right xs:w-full">
                    Payment For Approval
                  </span>
                ) : (
                  <button
                    className="bg-orange-700 hover:bg-orange-800 text-white font-bold py-1 px-2 mt-6 float-right xs:w-full"
                    type="button"
                    onClick={() => setOpen(true)}
                  >
                    Renew Membership
                  </button>
                )}
                {moment(data.validity).year() === moment().year() &&
                !isForEvaluation ? (
                  <button
                    className="bg-orange-700 hover:bg-orange-800 text-white font-bold py-1 px-2 mt-6 float-right xs:w-full mr-5"
                    type="button"
                    onClick={() => setOpen(true)}
                  >
                    Renew Membership
                  </button>
                ) : moment(data.validity).year() === moment().year() &&
                  isForEvaluation ? (
                  <span className="bg-blue-900 text-white font-bold py-1 px-2 mt-6 float-right xs:w-full mr-5">
                    Payment For Approval
                  </span>
                ) : (
                  ''
                )}
              </div>
            )}
          </div>
          <div className="flex flex-wrap -mx-auto mb-0 px-4 py-4">
            <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="lastname"
              >
                Lastname
              </label>
              <input
                className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="lastname"
                name="lastname"
                type="text"
                defaultValue={data.lastname}
                disabled
              />
            </div>
            <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="lastname"
              >
                Firstname
              </label>
              <input
                className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="lastname"
                name="lastname"
                type="text"
                defaultValue={data.firstname}
                disabled
              />
            </div>
            <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="middlename"
              >
                Middlename
              </label>
              <input
                className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="middlename"
                name="middlename"
                type="text"
                defaultValue={data.middlename}
                disabled
              />
            </div>
            <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="nickname"
              >
                Nickname
              </label>
              <input
                className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="nickname"
                name="nickname"
                type="text"
                defaultValue={data.nickname}
                disabled
              />
            </div>
            <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="birthdate"
              >
                Birthdate
              </label>
              <input
                className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="birthdate"
                name="birthdate"
                type="date"
                defaultValue={data.birthdate}
                disabled
              />
            </div>
            <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="birthdate"
              >
                Age
              </label>
              <input
                className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="age"
                name="age"
                type="number"
                value={age}
                required
              />
            </div>
            <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="civilStatus"
              >
                Civil Status
              </label>
              <input
                className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="civilStatus"
                name="civilStatus"
                type="text"
                defaultValue={data.civilStatus}
                disabled
              />
            </div>
            <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="contact"
              >
                Contact
              </label>
              <input
                className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="contact"
                name="contact"
                type="text"
                defaultValue={data.contact}
                disabled
              />
            </div>
            <div className="md:w-1/3 w-full px-3 mb-6 md:mb-4">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="occupation"
              >
                Occupation
              </label>
              <input
                className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="occupation"
                name="occupation"
                type="text"
                defaultValue={data.occupation}
                disabled
              />
            </div>
            <div className="md:w-1/4 w-full px-3 mb-6 md:mb-4">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="skills"
              >
                Skills/ Part Time Job
              </label>
              <input
                className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="skills"
                name="skills"
                type="text"
                value={data.skills}
              />
            </div>
            <div className="md:w-1/4 w-full px-3 mb-6 md:mb-4">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="barangay"
              >
                Barangay
              </label>
              <input
                className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="barangay"
                name="barangay"
                type="text"
                defaultValue={data.barangay}
                disabled
              />
            </div>
            <div className="md:w-1/4 w-full px-3 mb-6 md:mb-4">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="memberType"
              >
                Membership type
              </label>
              <input
                className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="memberType"
                name="memberType"
                type="text"
                value={data.type}
              />
            </div>
            <div className="md:w-1/4 w-full px-3 mb-6 md:mb-4">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="noOfChild"
              >
                Number of Children
              </label>
              <input
                className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="noOfChild"
                name="noOfChild"
                type="number"
                value={data.noOfChild}
              />
            </div>
          </div>
          <h5 className="w-full md:w-1/2 ml-5 mt-5 px-3 mb-0 md:mb-0 text-2xl font-bold">
            Beneficiary Information
          </h5>
          <div className="flex flex-wrap -mx-auto mb-6 px-4 py-4">
            <div className="md:w-1/4 w-full px-3 mb-6 md:mb-4">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="beneLastname"
              >
                Lastname
              </label>
              <input
                className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="beneLastname"
                name="beneLastname"
                type="text"
                defaultValue={data.beneficiary?.lastname}
                disabled
              />
            </div>
            <div className="md:w-1/4 w-full px-3 mb-6 md:mb-4">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="beneFirstname"
              >
                Firstname
              </label>
              <input
                className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="beneFirstname"
                name="beneFirstname"
                type="text"
                defaultValue={data.beneficiary?.firstname}
                disabled
              />
            </div>
            <div className="md:w-1/4 w-full px-3 mb-6 md:mb-4">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="beneMiddlename"
              >
                Middlename
              </label>
              <input
                className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="beneMiddlename"
                name="beneMiddlename"
                type="text"
                defaultValue={data.beneficiary?.middlename}
                disabled
              />
            </div>
            <div className="md:w-1/4 w-full px-3 mb-6 md:mb-4">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="beneRelationship"
              >
                Relationship
              </label>
              <input
                className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="beneRelationship"
                name="beneRelationship"
                type="text"
                defaultValue={data.beneficiary?.relationship}
                disabled
              />
            </div>
            <div className="w-full px-2 mb-4 md:mb-12">
              <h5 className="md:w-1/2 text-2xl mb-4 font-bold px-4">
                Membership Information
              </h5>
              <div className="flex">
                <div className="md:w-1/2 w-full px-3 mb-6 md:mb-4">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="mid"
                  >
                    ID Number
                  </label>
                  <input
                    className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="mid"
                    name="mid"
                    type="text"
                    defaultValue={data.mid}
                    disabled
                  />
                </div>
                <div className="md:w-1/2 w-full px-3 mb-6 md:mb-4">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="validity"
                  >
                    Valid Until
                  </label>
                  <input
                    className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="validity"
                    name="validity"
                    type="text"
                    defaultValue={data.validity}
                    disabled
                  />
                </div>
              </div>
              <div className="flex"></div>
            </div>
          </div>
          <div className="w-full px-3 mb-12 md:mb-12">
            <div className="grid grid-cols-2 gap-4 md:grid-cols-2 sm:grid-cols-0 sm:grid-cols-1 px-4">
              {!loadingAttachment && images ? (
                <img className="rounded-lg h-40" src={images} alt={id} />
              ) : (
                <div>
                  <PhotoDropzone id={id} title="Upload Member Photo" />
                </div>
              )}
              {!loadingAttachment && signatureData ? (
                <img className="rounded-lg h-40" src={signatureData} alt={id} />
              ) : (
                <div>
                  <SignatureDropzone id={id} title="Upload Member Signature" />
                </div>
              )}
            </div>
          </div>
          <div className="w-full px-2 mb-4 md:mb-12">
            <h5 className="md:w-1/2 text-2xl mb-4 font-bold px-4">Remarks</h5>
            <p className="px-4"> {data.remarks}</p>
          </div>
          <div className="w-full px-2 mb-4 md:mb-12">
            <h5 className="md:w-1/2 text-2xl mb-4 font-bold px-4">
              Benefits Availed
            </h5>
            {data.benefits && (
              <div className="px-4">
                <Table tableData={data.benefits} columns={benefitColumns} />
              </div>
            )}
          </div>
          <div className="w-full px-2 mb-4 md:mb-12">
            <h5 className="md:w-1/2 text-2xl px-4 mb-4 font-bold">
              Payment Information
            </h5>
            {data.payments && (
              <div className="px-4">
                <Table tableData={data.payments} columns={columns} />
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    attachments: state.attachments.attachments,
    payment: state.payments.payment,
    picture: state.attachments.picture,
    signature: state.attachments.signature,
    member: state.members.member,
    photoCreated: state.attachments.photoCreated,
    signatureCreated: state.attachments.signatureCreated,
    loading: state.members.loading,
    loadingAttachment: state.attachments.loading,
    error: state.members.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreatePayment: (data) => dispatch(actions.createPayment(data)),
    onFetchOneMember: (data) => dispatch(actions.fetchOneMember(data)),
    onFetchMemberPicture: (data) => dispatch(actions.fetchMemberPicture(data)),
    onFetchMemberSignature: (data) =>
      dispatch(actions.fetchMemberSignature(data)),
    onResetAttachments: () => dispatch(actions.resetAttachments()),
    onResetPassword: (data) => dispatch(actions.resetUserPassword(data)),
    onResetPayments: () => dispatch(actions.resetPayments()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
